import React from "react"
import * as formcontactStyles from "./formcontact.module.css"

export default function Formcontact () {
    return (
        <form id="contactform" method="post" netlify-honeypot="bot-field" data-netlify="true" name="contactform">
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contactform" />
            <select className={formcontactStyles.formcontact} id="topic" name="topic" placeholder="เรื่องที่จะให้ติดต่อ">
                <option value="quote">ขอใบเสนอราคา</option>
                <option value="product">สนใจสินค้า</option>
                <option value="problum">อื่นๆหรือปัญหาการใช้งาน</option>
            </select>
            <div className={formcontactStyles.contactflex}>
                <input className={formcontactStyles.formcontact} type="text" placeholder="ชื่อ-นามสกุล" name="ชื่อ-นามสกุล" required />
                <input className={formcontactStyles.formcontact} type="text" placeholder="บริษัท" name="บริษัท" required />
            </div>
            <div className={formcontactStyles.contactflex}>
                <input className={formcontactStyles.formcontact} type="email" placeholder="อีเมล" name="อีเมล" required />
                <input className={formcontactStyles.formcontact} type="tel" placeholder="เบอร์โทรศัพท์" name="เบอร์โทรศัพท์" required />
            </div>
                <textarea className={formcontactStyles.formcontact} name="message" rows="5" placeholder="รายละเอียด" required></textarea>
                <button className={formcontactStyles.buttomsubmit} type="submit">Submit</button>
        </form>
    )
}