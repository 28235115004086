import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Icon } from '@iconify/react'
import arrowRight from '@iconify/icons-akar-icons/arrow-right'
import lineIcon from '@iconify/icons-bi/line'
import scrollTo from "gatsby-plugin-smoothscroll"

const StickyWrapper = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #050248;
    border-top-left-radius: 15px 15px;
    border-top-right-radius: 15px 15px;
    padding: 10px 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    .image-logo-footer {
        display: flex;
        justify-content: center;
    }
    .stickybar {
        display: block;
    }
    .text-p-footer {
        margin-bottom: 0rem;
        color: #fff;
        text-align: center;
    }
    .text-footer {
        width: 0rem;
    }
    .button {
        padding: 0 5px;
    }
    .button-register {
        background-color: #fff;
        color: #050248;
        border-radius: 30px;
        font-size: 12px;
        font-weight: 400;
        padding: 8px 8px;
        width: 150px;
        border: 3px solid #050248;
    }
    .button-register:hover {
      background-color: #050248;
      color: #fff;
      border: 3px solid #050248;
    }
    .button-line {
      background-color: #39cd00;
      border: 3px solid #050248;
      color: #fff;
      border-radius: 30px;
      font-size: 12px;
      font-weight: 400;
      padding: 0;
      width: 150px;
      padding: 8px 8px;
    }
    .button-line:hover {
      border: 3px solid #39cd00;
    }
    @media only screen and (min-width: 1200px) {
        .stickybar {
            display: flex;
        }
        .button {
            padding: 0 15px;
        }
        .text-footer {
            width: 12rem;
        }
        .button-register {
            background-color: #fff;
            color: #050248;
            border-radius: 30px;
            font-size: 16px;
            font-weight: 400;
            padding: 10px 10px;
            width: 200px;
            border: 3px solid #050248;
        }
        .button-register:hover {
            background-color: #050248;
            color: #fff;
            border: 3px solid #050248;
        }
        .button-line {
        background-color: #fff0;
        border: 3px solid #fff0;
        color: #39cd00;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 400;
        padding: 0;
        margin-left: 5px;
        width: 200px;
        padding: 10px 10px;
        }
        .button-line:hover {
        border: 3px solid #39cd00;
        }
    }
`

const StickyFooter = () => {
    return (
        <StickyWrapper>
            <Container fluid="xl">
                <Row>
                    <Col md={12} className="stickybar align-items-center justify-content-center">
                        <div className="image-logo-footer">
                            <StaticImage src="../../static/image/logo-white.png" alt="" />
                        </div>
                        <div className="text-footer"></div>
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="button">
                                <a
                                href="https://lin.ee/7y11wvo"
                                target="_blank"
                                rel="noreferrer"
                                className="button-line btn btn-primary"
                                >
                                    <Icon icon={lineIcon} /> ติดต่อสอบถาม{" "}
                                    <Icon icon={arrowRight} />
                                </a>
                            </div>
                            <div className="button">
                                <a onClick={() => scrollTo('#form')} className="button-register btn btn-primary">
                                    ขอใบเสนอราคา <Icon icon={arrowRight} />
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StickyWrapper>
    )
}

export default StickyFooter