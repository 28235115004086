import React from "react"
import * as headerStyles from "./headerlp.module.css"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col, Button } from "react-bootstrap"

export default function HeaderLanding() {
    return (
        <Container fluid="xl" className={headerStyles.containerheader}>
            <Row>
                <Col md={6} className={headerStyles.colcenter}>
                    <div className="d-flex align-items-center">
                        <div>
                            <StaticImage src="../../static/image/logo-stt.png" alt="Songthai" />
                        </div>
                        <div className={headerStyles.divtextlogo}>
                            <p className={headerStyles.logoonename}>SongThaiTextile</p>
                            <p className={headerStyles.logotwoname}>บริษัท ทรงไทยเท็กซ์ไทล์ จำกัด</p>
                        </div>
                    </div>
                </Col>
                <Col md={6} className={headerStyles.colaction}>
                    <div className="d-flex align-items-center justify-content-end">
                        <div>
                            <p>นึกถึงเส้นด้าย นึกถึงเรา</p>
                        </div>
                    </div>
                </Col>
            </Row>
      </Container>
    );
}

